import styles from './index.module.css'
import { useForm } from 'react-hook-form'
import { useCallback, useMemo, useState, forwardRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import fetcher from '@lib/fetcher'
import { getAdCookie } from '@lib/utils/cookies'
import { I18N_STORE_DOMAIN } from '@shopify/const'
import { LoadingDots } from '@components/ui'
import cn from 'classnames'
import { IMaskInput } from 'react-imask'

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="+1 (000) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  )
})

const RegistrationsSubscribeSMS = ({
  gs,
  data,
  registerEmail,
  onSubscribeSMSSuccess,
  onSubscribeSMSSkip,
  subscribe,
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    mode: 'all',
  })
  const s = { ...styles, ...gs }
  const [submitLoading, setSubmitLoading] = useState(false)
  const [networkError, setNetworkError] = useState('')
  const [countDownTime, setCountDownTime] = useState(0)
  const [dealsType, setDealsType] = useState('')
  const { locale } = useRouter()

  const onSubmit = async (formData) => {
    if (Reflect.ownKeys(errors).length > 0) return
    setSubmitLoading(true)
    const { phone_number } = formData?.subscribeSMS
    const { register_source } = getAdCookie()
    let url = '/api/multipass/rainbowbridge/activities'
    const res = await fetcher({
      locale,
      url,
      action: 'activity',
      method: 'POST',
      body: {
        email: registerEmail,
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: dealsType,
        phone_number,
        promise_subscribe: subscribe,
      },
      headers: {
        'node-validate': 'none',
      },
      needRecaptcha: true,
      recaptchaAction: 'activity',
    }).finally(() => setSubmitLoading(false))
    if (res.status > 100 && res.status < 300) {
      onSubscribeSMSSuccess && onSubscribeSMSSuccess()
    } else {
      if (res.status === 400) {
        setError('subscribeSMS[phone_number]', {
          type: 'custom',
          message: data?.validPhoneNumberText,
        })
        return
      }
      if (res.status === 409) {
        setError('subscribeSMS[phone_number]', {
          type: 'custom',
          message:
            data?.validPhoneSubscribedText ||
            'This phone number is already subscribed.',
        })
        return
      }
      setCountDownTime(data?.countDownTime)
      // start_ai_generated
      let timer = setInterval(() => {
        setCountDownTime((prev) => {
          if (prev <= 0) {
            clearInterval(timer)
            onSubscribeSMSSkip && onSubscribeSMSSkip()
            return 0
          }
          return prev - 1
        })
      }, 1000)
      // end_ai_generated
      setNetworkError(data?.sms_interfaceError)
    }
  }

  useEffect(() => {
    setDealsType(
      window.screen.width < 768 ? data?.dealsType_mob : data?.dealsType_pc
    )
  }, [data?.dealsType_mob, data?.dealsType_pc])

  const handleFocus = useCallback(() => {
    clearErrors('')
    setNetworkError && setNetworkError('')
  }, [clearErrors])

  return (
    <registrations-subscribe-sms className={s.registrations_subscribe_sms}>
      <div className={s.registrations_subscribe_sms_wrap}>
        <div className={s.registrations_subscribe_sms_head}>
          <p className={cn(s.registrations_title, s.rss_title)}>
            {data?.sms_title}
          </p>
        </div>
        <div className={s.registrations_subscribe_sms_body}>
          <p
            className={s.rss_desc}
            dangerouslySetInnerHTML={{ __html: data?.sms_desc }}
          />
        </div>
        <div className={s.registrations_subscribe_sms_footer}>
          <form className={s.rss_form} onSubmit={handleSubmit(onSubmit)}>
            <div className={s.rss_form_item}>
              <label className={s.rss_sms_label}>{data?.sms_label}</label>
              <div className={cn(s.rss_form_item)}>
                <TextMaskCustom
                  type="phone"
                  name="subscribeSMS[phone_number]"
                  onFocus={() => handleFocus()}
                  className={cn(s.registrations_input, {
                    [s.input_error]:
                      errors?.subscribeSMS?.phone_number?.message,
                  })}
                  placeholder={data.sms_placeholder}
                  {...register('subscribeSMS[phone_number]', {
                    required: data?.phoneEmptyError,
                  })}
                />
              </div>
            </div>
            <p className={s.form_error}>
              {errors?.subscribeSMS?.phone_number?.message}
            </p>
            {networkError && countDownTime > 0 && (
              <p
                className={cn(
                  s.form_error,
                  'rounded-[2px] bg-[rgba(248,77,79,0.08)] px-[12px] py-[10px]'
                )}
              >
                {data.sms_interfaceError}
                &nbsp;<span>{countDownTime}</span>&nbsp;
                <span>{data?.secondsText}</span>
              </p>
            )}
            <div className={s.rss_btn_wrap}>
              <button
                type="submit"
                className={cn(
                  s.rss_btn,
                  s.rss_loading_btn,
                  s.registrations_btn,
                  {
                    ['pointer-events-none']: !!countDownTime || submitLoading,
                  }
                )}
              >
                {submitLoading ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  data?.sms_subscribe
                )}
              </button>
              <button
                tag="Auto-10000626"
                onClick={onSubscribeSMSSkip}
                className={s.sms_skip}
              >
                {data?.sms_skip}
              </button>
            </div>
          </form>
        </div>
      </div>
    </registrations-subscribe-sms>
  )
}

export default RegistrationsSubscribeSMS
