import RegistrationsModel from './components/RegistrationsModel'
import { useState, useCallback, useEffect, useMemo } from 'react'
import s from './index.module.css'
import useCustomer from '@shopify/customer/use-customer'
import registrationsTrack from './RegistrationsTrack'
import { RegistrationsStatus, getAppName } from './const'
import { MULPASS_URL, I18N_STORE_DOMAIN } from '@shopify/const'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import Cookies from 'js-cookie'
import { getURLParameter } from '@lib/utils/tools'
import { getQuery, getAdCookie } from '@lib/utils/cookies'
import fetcher from '@lib/fetcher'

const COUNTRY = {
  us: 'US',
  ca: 'CA',
  'eu-de': 'DE',
  'eu-en': 'AT',
  uk: 'GB',
  nl: 'LU',
  'eu-fr': 'FR',
  'eu-it': 'IT',
  'eu-es': 'ES',
  'cz-en': 'CZ',
}

const Registrations = ({
  registrations,
  showRegistrationsPopup,
  path,
  pathname,
  registrationsModalMetafields,
  pageType,
  globalCountry,
  showGoogleIdentity,
}) => {
  const { data: customer } = useCustomer()
  const { locale } = useRouter()
  const email = useMemo(() => (customer ? customer?.email : ''), [customer])
  const [showRegistrationsPop, setShowRegistrationsPop] = useState(false)
  const { setRegistrationsPopup, leftBarShow, recaptchaReady } = useUI()
  const [isGoogleLoginNew, setIsGoogleLoginNew] = useState(false)
  const [showGoogleLoginCoupon, setShowGoogleLoginCoupon] = useState(false)
  const [googleLoginCoupons, setGoogleLoginCoupons] = useState([])

  useEffect(() => {
    setIsGoogleLoginNew(getURLParameter('isNew') === 'true')
  }, [])

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const activities = useCallback(async () => {
    const { register_source } = getAdCookie()
    const sub_brand_type_content = () => {
      let text = ''
      switch (pageType) {
        case 'powerStation':
          return (text = 'anker_ankersolix')
        case 'charger':
          return (text = 'anker_charging')
        case 'hes':
          return (text = 'anker_ankersolix')
        default:
          text
      }
    }
    let url = '/api/multipass/rainbowbridge/activities'
    const result = await fetcher({
      locale,
      url,
      method: 'POST',
      headers: {
        'X-Application-Name': `${app}-shopify`,
        'x-application-token': Cookies.get(`${app}-info`),
      },
      body: {
        email,
        register_source,
        shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
        single_brand_subscribe: true,
        genre: 'anker_register_and_subscribe',
        promise_subscribe: false,
        sub_brand_type: sub_brand_type_content(),
      },
      needRecaptcha: true,
      recaptchaAction: 'activity',
    })
    if (!result.errors) {
      setGoogleLoginCoupons(result.data?.coupons)
      setShowGoogleLoginCoupon(true)
      const urlObj = new URL(location.href)
      urlObj.searchParams.delete('isNew')
      history.replaceState({}, '', urlObj.toString())
    }
  }, [app, email, locale])

  useEffect(() => {
    if (showGoogleIdentity && isGoogleLoginNew && email && recaptchaReady) {
      activities()
    }
  }, [activities, email, isGoogleLoginNew, recaptchaReady, showGoogleIdentity])

  const handleRegistrationsModelClose = useCallback(
    (status) => {
      switch (status) {
        case RegistrationsStatus.QUICK_LOGIN:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'login_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.REMAINDER:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.MAIN:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.PASSWORD_LOGIN:
          registrationsTrack.closeTrack({
            position: '',
            page_group: 'login_pop',
            button_name: 'close',
          })
          break
        case RegistrationsStatus.COUPONS:
          registrationsTrack.closeTrack({
            position: 'success',
            page_group: 'reg_sub_pop',
            button_name: 'close',
          })

          if (showGoogleLoginCoupon) {
            setShowGoogleLoginCoupon(false)
            setRegistrationsPopup(false)
            setShowRegistrationsPop(false)
            return
          }

          const hostUrl = window.location.host.split('.')
          const domain = window.location.host.includes('localhost')
            ? 'localhost'
            : `.${hostUrl[1]}.${hostUrl[2]}`
          const b_auth_token = localStorage.getItem('b_auth_token')
          const passportThemeLoginUrl = Cookies.get('passport-theme-url')
          passportThemeLoginUrl &&
            Cookies.remove('passport-theme-url', {
              domain,
              expires: 30,
            })
          window.location.href = `${MULPASS_URL}/theme-login?app=${getAppName(
            locale
          )}&t=${b_auth_token}&redirect=${encodeURIComponent(location.href)}`
          break
        default:
          break
      }
      setRegistrationsPopup(false)
      setShowRegistrationsPop(false)
    },
    [locale, setRegistrationsPopup, showGoogleLoginCoupon]
  )

  const showRegistrationsModal = useMemo(() => {
    let result = false
    if (
      // 系列页
      registrationsModalMetafields?.collections_handles &&
      path?.includes('collections')
    ) {
      const paths = path.toLowerCase().split('/')
      for (const collection_handle of registrationsModalMetafields?.collections_handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === collection_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!collection_handle && temp
        if (result) break
      }
    }
    // blog页
    if (
      registrationsModalMetafields?.blogs_handles &&
      path?.includes('blogs')
    ) {
      const paths = path.toLowerCase().split('/')
      for (const blog_handle of registrationsModalMetafields.blogs_handles) {
        if (blog_handle === 'home' && pathname === '/blogs') {
          result = true
        } else {
          let temp = false
          for (const pathItem of paths) {
            temp = pathItem.toLowerCase() === blog_handle.toLowerCase()
            if (temp) {
              break
            }
          }
          result = !!blog_handle && temp
        }
        if (result) break
      }
    }
    // page页
    if (
      registrationsModalMetafields?.pages_handles &&
      pathname === '/[...pages]'
    ) {
      const paths = path.toLowerCase().split('/')
      for (const pages_handle of registrationsModalMetafields?.pages_handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === pages_handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!pages_handle && temp
        if (result) break
      }
    }
    // 产品
    if (registrationsModalMetafields?.handles && path?.includes('products')) {
      const paths = path.toLowerCase().split('/')
      for (const handle of registrationsModalMetafields?.handles) {
        let temp = false
        for (const pathItem of paths) {
          temp = pathItem.toLowerCase() === handle.toLowerCase()
          if (temp) {
            break
          }
        }
        result = !!handle && temp
        if (result) break
      }
    }

    // 首页首页默认展示
    if (
      (registrationsModalMetafields?.chgIndex && path === '/') ||
      (registrationsModalMetafields?.ppsIndex && path === '/anker-solix')
    ) {
      result = true
    }

    // 全局开关, 全站设置关闭即不展示
    if (registrationsModalMetafields?.storeFrontIndex === false) {
      result = false
    }

    return result
  }, [registrationsModalMetafields, path, pathname])

  // 设置Cookie
  const setItemWithExpiry = useCallback((key, value, timestamp, domain) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + timestamp, // 设置过期时间
      timestamp,
    }
    Cookies.set(key, item, {
      domain,
      expires: 30,
    })
  }, [])

  // 获取Cookie
  const getItemWithExpiry = useCallback((key, domain) => {
    const itemString = Cookies.get(key)
    if (!itemString) {
      return null
    }
    const item = JSON.parse(itemString)
    const now = new Date()

    // 检查是否过期
    if (now.getTime() > item.expiry) {
      Cookies.remove(key, {
        domain,
        expires: 30,
      })
      return null
    }
    return item
  }, [])

  // 设置是否弹窗标识
  useEffect(() => {
    const hostUrl = window.location.host.split('.')
    const domain = window.location.host.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`

    const registrations_pop_up = getItemWithExpiry(
      `${COUNTRY?.[locale]}_registrations_pop_up`,
      domain
    )

    if (showRegistrationsPopup) {
      registrationsTrack.pvTrack()
    }

    // 参数符合则强制弹框
    let popRegistrations
    const query = getQuery()
    const handleItem = registrationsModalMetafields?.condition_display?.find(
      (item) => pathname === item?.handle
    )
    popRegistrations =
      query[handleItem?.params] &&
      query[handleItem?.params] === handleItem?.value

    if (!registrations_pop_up || popRegistrations) {
      const timer = setTimeout(() => {
        if ((!email && showRegistrationsModal) || popRegistrations) {
          registrationsTrack.pvTrack()
          setShowRegistrationsPop(true)
          setItemWithExpiry(
            `${COUNTRY?.[locale]}_registrations_pop_up`,
            true,
            14 * 24 * 60 * 60 * 1000,
            domain
          )
        }
        clearTimeout(timer)
      }, 10000)
    }

    // 存在用户直接刷新页面的情况，重定向到passport，带上登录态
    const passportThemeLoginUrl = Cookies.get('passport-theme-url')
    if (passportThemeLoginUrl) {
      Cookies.remove('passport-theme-url', {
        domain,
        expires: 30,
      })
      window.location.href = passportThemeLoginUrl
    }
  }, [
    email,
    showRegistrationsPopup,
    locale,
    getItemWithExpiry,
    setItemWithExpiry,
    showRegistrationsModal,
  ])

  const registrationsModalStatus = useMemo(() => {
    return (
      showRegistrationsPopup ||
      (!email && showRegistrationsPop) ||
      showGoogleLoginCoupon
    )
  }, [
    showRegistrationsPopup,
    email,
    showRegistrationsPop,
    showGoogleLoginCoupon,
  ])

  useEffect(() => {
    if (registrationsModalStatus) {
      document.querySelector('html').classList.add('fw-hidden')
    } else if (!leftBarShow) {
      document.querySelector('html').classList.remove('fw-hidden')
    }
  }, [registrationsModalStatus, leftBarShow])

  return (
    registrationsModalStatus && (
      <>
        <div aria-label="mask" className={s.rsm_close_mask}></div>
        <RegistrationsModel
          onClose={handleRegistrationsModelClose}
          gs={s}
          locale={locale}
          pageType={pageType}
          data={registrations}
          globalCountry={globalCountry}
          showGoogleLoginCoupon={showGoogleLoginCoupon}
          googleLoginCoupons={googleLoginCoupons}
        />
      </>
    )
  )
}

export default Registrations
