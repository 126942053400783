import { Picture, Text } from '@components/ui'
import styles from './index.module.css'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import { validate } from 'email-validator'
import { useCallback, useMemo, useState } from 'react'
import fetcher from '@lib/fetcher'
import { useRouter } from 'next/router'
import { getAdCookie } from '@lib/utils/cookies'
import { I18N_STORE_DOMAIN, MULPASS_URL } from '@shopify/const'
import registrationsTrack from '../../RegistrationsTrack'
// start_ai_generated
import Cookies, { set } from 'js-cookie'
// end_ai_generated
import { isEuLocale } from '@shopify/const'
import { getAppName } from '../../const'
import { useUI } from '@components/ui/context'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const defaultSubscribeCountry = ['us', 'ca']

const RegistrationsMain = ({
  data,
  registrationsFillEmail,
  gs,
  onQuickLogin,
  onRegistrationsSuccess,
  onRegistrationsError,
  onSendVerifyEmailSuccess,
  onRegistrationsVerify,
  pageType,
}) => {
  const { registrationsDealsType, fbqTrack } = useUI()
  const { setHtmlRelativeLink } = useRelativeLink()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [canAutoSignup, setCanAutoSignup] = useState(true)
  const [serviceError, setServiceError] = useState(false)
  const [formData, setFormData] = useState(true)
  const [sendLoading, setSendLoading] = useState(false)
  const s = { ...styles, ...gs }
  const { locale } = useRouter()
  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    defaultValues: { registrations: { email: registrationsFillEmail } },
  })

  const app = useMemo(() => {
    return getAppName(locale)
  }, [locale])

  const handleSendVerifyEmail = useCallback(async () => {
    if (sendLoading) return
    setSendLoading(true)
    const res = await fetcher({
      locale,
      url: '/api/multipass/account/users/send_validation_email',
      method: 'POST',
      body: {
        email: formData?.registrations,
        app,
      },
      needRecaptcha: true,
      recaptchaAction: 'user',
    }).catch((e) => {
      console.log(e, 'e')
    })
    if (res.status > 100 && res.status < 300) {
      onSendVerifyEmailSuccess && onSendVerifyEmailSuccess()
    }
    setSendLoading(false)
  }, [
    sendLoading,
    locale,
    formData?.registrations,
    app,
    onSendVerifyEmailSuccess,
  ])

  const handleFocus = useCallback(() => {
    // start_ai_generated
    clearErrors('registrations[email]')
    setServiceError(false)
    // end_ai_generated
  }, [clearErrors])

  const getDomain = useCallback(() => {
    const hostUrl = window.location.host.split('.')
    return location.href.includes('localhost')
      ? 'localhost'
      : `.${hostUrl[1]}.${hostUrl[2]}`
  }, [])

  const setCookie = useCallback(
    ({ auth_token, multipassinfo }) => {
      const domain = getDomain()
      Cookies.set(`${app}-token`, auth_token, {
        domain,
        expires: 30,
      })
      Cookies.set(`${app}-info`, auth_token, {
        domain,
        expires: 30,
      })
      Cookies.set(`alpcEU`, isEuLocale(locale) ? '1' : '0', {
        domain,
        expires: 30,
      })
    },
    [app, getDomain]
  )

  const isDefaultSubscribeCountry = useMemo(
    () => defaultSubscribeCountry.includes(locale),
    [locale]
  )

  const onSubmit = useCallback(
    async (formData) => {
      setSubmitLoading(true)
      setFormData(formData)
      const { email, subscribe } = formData?.registrations
      const sub_brand_type_content = () => {
        let text = ''
        switch (pageType) {
          case 'powerStation':
            return (text = 'anker_ankersolix')
          case 'charger':
            return (text = 'anker_charging')
          case 'hes':
            return (text = 'anker_ankersolix')
          default:
            text
        }
      }
      const res = await fetcher({
        locale,
        url: '/api/multipass/passport-api/auto-signup',
        method: 'POST',
        action: 'login',
        headers: {
          'node-validate': 'none',
        },
        body: {
          email,
          app,
        },
        needRecaptcha: true,
        recaptchaAction: 'login',
      }).catch((e) => {
        console.log(e, 'e')
        setSubmitLoading(false)
      })
      const domain = getDomain()

      const { register_source } = getAdCookie()
      if (res.status > 100 && res.status < 300) {
        const { auth_token, canAutoSignup, multipassinfo } = res?.data?.data
        registrationsTrack.registrationsTrack({
          action: 'success',
          subscribe: subscribe ? 1 : 0,
          email,
          deals_type: data?.dealsType,
          fbqTrack,
        })
        const b_auth_token = btoa(auth_token)
        setCookie({ auth_token, multipassinfo })
        setCanAutoSignup(canAutoSignup)
        if (canAutoSignup) {
          localStorage.setItem('b_auth_token', b_auth_token)
          const passportThemeLoginUrl = `${MULPASS_URL}/theme-login?app=${app}&t=${b_auth_token}&redirect=${location.href}`
          Cookies.set('passport-theme-url', passportThemeLoginUrl, {
            domain,
            expires: 30,
          })
          // 注册成功
          let url = '/api/multipass/rainbowbridge/activities'
          const result = await fetcher({
            locale,
            url,
            action: 'activity',
            method: 'POST',
            headers: {
              'X-Application-Name': `${app}-shopify`,
              'x-application-token': res?.data?.data?.auth_token,
            },
            body: {
              email,
              register_source,
              shopify_domain: I18N_STORE_DOMAIN[`${locale}`],
              single_brand_subscribe: true,
              genre: registrationsDealsType || data?.dealsType,
              promise_subscribe: subscribe,
              sub_brand_type: sub_brand_type_content(),
            },
            needRecaptcha: true,
            recaptchaAction: 'activity',
          }).finally(() => setSubmitLoading(false))
          if (!result.errors) {
            // 订阅成功
            onRegistrationsSuccess &&
              onRegistrationsSuccess(result, email, subscribe)
          }
        } else {
          onRegistrationsVerify && onRegistrationsVerify(email)
          setSubmitLoading(false)
        }
      } else {
        setSubmitLoading(false)
        setServiceError(true)
        // 注册失败
        onRegistrationsError && onRegistrationsError(res, email)
        if (res.status === 422) {
          setServiceError(false)
        }
      }
    },
    [
      onRegistrationsVerify,
      locale,
      data,
      onRegistrationsSuccess,
      onRegistrationsError,
      app,
      getDomain,
      registrationsDealsType,
      setCookie,
    ]
  )

  return (
    <registrations-main
      class={cn(s.registrations_main, { [s.disabled]: submitLoading })}
    >
      <div className={s.registrations_model_head}>
        <Picture
          source={`${data?.img_banner_pc}, ${data?.img_banner_mob} 1024`}
          className="aspect-h-[256] aspect-w-[358] w-full min-l:aspect-h-[328] min-l:aspect-w-[540]"
        />
        <Text
          html={data?.title}
          className={cn(s.registrations_title, s.rsm_title)}
        />
      </div>
      <div className={s.registrations_model_body}>
        <form
          method="post"
          className={s.rsm_form}
          acceptCharset="UTF-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            className={cn(s.rsm_input, s.registrations_input, {
              [s.input_error]: errors?.registrations?.email,
            })}
            name="registrations[email]"
            tag="Auto-10000612"
            onFocus={handleFocus}
            maxLength="200"
            aria-label={data?.placeholder}
            placeholder={data?.placeholder}
            {...register('registrations[email]', {
              required: data?.info_fill_email,
              validate: (value) => validate(value) || data?.info_err_email,
            })}
          />
          <p className={s.form_error}>
            {errors?.registrations?.email?.message}
          </p>
          <p className={cn(s.rsm_policy_box, s.checkout_box)}>
            <input
              className={s.rsm_checkout}
              name="registrations[policy]"
              tag="Auto-10000615"
              type="checkbox"
              defaultChecked={isDefaultSubscribeCountry}
              aria-label="policy-checkbox"
              {...register('registrations[policy]', {
                required: data?.info_select_policy,
              })}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: setHtmlRelativeLink({ html: data?.policy_text }),
              }}
              className={s.rsm_checkout_text}
            ></span>
          </p>
          <p className={s.form_error}>
            {errors?.registrations?.policy?.message}
          </p>
          <p className={cn(s.rsm_subscribe_box, s.checkout_box)}>
            <input
              className={s.rsm_checkout}
              name="registrations[subscribe]"
              tag="Auto-10000616"
              type="checkbox"
              defaultChecked={isDefaultSubscribeCountry}
              aria-label="subscribe-checkbox"
              {...register('registrations[subscribe]')}
            />
            <span className={s.rsm_checkout_text}>{data.subscribe_text}</span>
          </p>
          <p className={s.form_error}>
            {errors?.registrations?.subscribe?.info_select_policy}
          </p>
          {!canAutoSignup && (
            <p
              className={cn(
                s.form_error,
                'rounded-[2px] bg-[rgba(248,77,79,0.08)] px-[12px] py-[10px]'
              )}
            >
              <span>{data?.verifyError}</span>&nbsp;
              <a
                onClick={handleSendVerifyEmail}
                className=" cursor-pointer text-anker-color underline"
              >
                {data?.sendEmailText}
              </a>
            </p>
          )}
          {serviceError && (
            <p
              className={cn(
                s.form_error,
                'rounded-[2px] bg-[rgba(248,77,79,0.08)] px-[12px] py-[10px]'
              )}
            >
              <span>{data?.serviceError}</span>
            </p>
          )}
          {submitLoading ? (
            <button
              className={cn(
                s.rsm_submit,
                s.registrations_btn,
                s.flex_center,
                s.loading_btn
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M1 10C1 14.9706 5.02944 19 10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          ) : (
            <button
              type="submit"
              tag="Auto-10000617"
              className={cn(s.rsm_submit, s.registrations_btn, s.flex_center)}
            >
              {data?.register_text}
            </button>
          )}
        </form>
        <p className={s.rsm_login}>
          {data.login_text}
          <a
            onClick={() =>
              onQuickLogin && onQuickLogin(getValues('registrations[email]'))
            }
          >
            {data?.login_now_text}
          </a>
        </p>
      </div>
    </registrations-main>
  )
}

export default RegistrationsMain
